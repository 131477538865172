import { AbstractModelValidator } from '@koddington/ga-common';
import { ChannelChangeViewModel } from '../../../models/events/components-models/channel-change-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class ChannelChangeValidator extends AbstractModelValidator<ChannelChangeViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId)
            .requiredAndNotZero()
            .min(1);

        this.ruleForControl(m => m.smsRestricted)
            .required()
            .min(0).max(1);

        this.ruleForControlIf(u => u.smsRestrictedDate, u => u.smsRestricted.strictValue > 0)
            .required();

        this.ruleForControl(m => m.emailRestricted)
            .required()
            .min(0).max(1);

        this.ruleForControlIf(u => u.emailRestrictedDate, u => u.emailRestricted.strictValue > 0)
            .required();
    }
}
