import { AbstractModelValidator } from '@koddington/ga-common';
import { RegistrationStateChangeViewModel } from '../../../models/events/components-models/registration-state-change.view-model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RegistrationStateChangesValidator extends AbstractModelValidator<RegistrationStateChangeViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId)
            .requiredAndNotZero();

        this.ruleForControl(m => m.oldState)
            .required()
            .min(0);

        this.ruleForControl(m => m.newState)
            .required()
            .min(0);

        this.ruleForControl(m => m.eventDate)
            .required();
    }
}
