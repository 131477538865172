import { Component, OnInit } from '@angular/core';
import { AddFreeBetViewModel } from '../../../../models/events/components-models/add-free-bet-view-model';
import { AddFreeBetValidator } from '../../validators/add-free-bet-validator';
import { AddFreeBetForm } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, GaPanelGridConfig, GaStrictModelFactory, GaTitlePositions } from '@koddington/ga-common';
import { FreebetsService } from '../../../../services/autogen/Events';

@Component({
    selector: 'app-add-free-bet',
    templateUrl: './add-free-bet.component.html'
})
export class AddFreeBetComponent implements OnInit {

    public readonly model: AddFreeBetViewModel = new AddFreeBetViewModel();
    config = new GaPanelGridConfig();

    constructor(private readonly freebetsService: FreebetsService,
                private readonly validator: AddFreeBetValidator,
                private readonly messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.buildGrid();
    }

    public add(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(AddFreeBetForm, this.model);

        this.freebetsService.freebetAdd(form).pipe(
            take(1),
            filter(u => !this.messaging.tryShowError(u))
        ).subscribe(() => this.messaging.showMessage('Фрибет успешно добавлен'));
    }

    private buildGrid() {
        this.config.columnsCount = 5;
        this.config.titlePositions = GaTitlePositions.Top;
        this.config.addNumbers(this.model.userId, 'Id пользователя')
            .addNumbers(this.model.freeBetId, 'Id фрибета')
            .addDateTime(this.model.beginDate, 'Дата начала')
            .addDateTime(this.model.endDate, 'Дата окончания')
            .addNumbers(this.model.freeBetType, 'Тип фрибета. Смотри справочник купонов (WB-A-216 в базе знаний)')
            .addNumbers(this.model.issuedFreebetId, 'Id конкретного экзепляра купона, выданного пользователю')
            .addNumbers(this.model.summ, 'Сумма')
            .addNumbers(this.model.typeForBonusClub, 'Тип для бонус клуба (Пусто для обычных фрибетов, заполняется для бонусов за достижения бонус клуба.)');
    }
}
