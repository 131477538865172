import {IDropdownStrategy} from '@koddington/ga-common';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { Observable, of } from 'rxjs';
import {EnumExtensions, isNullOrUndefined} from '@koddington/ga-common';
import { Injectable } from '@angular/core';
import { WlMonetaryClassTypes, WlMonetaryClassTypesExtensions } from 'src/app/services/autogen/Users';

@Injectable({
  providedIn: 'root'
})
export class MonetaryClassTypesDropdownStrategy implements IDropdownStrategy<WlMonetaryClassTypes> {

  map(model: WlMonetaryClassTypes): DropdownItem<WlMonetaryClassTypes> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlMonetaryClassTypes>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlMonetaryClassTypesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlMonetaryClassTypes>> {
    const types = EnumExtensions.toArray(WlMonetaryClassTypes);
    return of(types);
  }
}
