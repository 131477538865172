import {IDropdownStrategy} from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {
    WlMtsSmsConversionCodes, WlMtsSmsConversionCodesExtensions
} from 'src/app/services/autogen/SmsGate';

@Injectable({
    providedIn: 'root'
})
export class MtsExoConversionCodesDropdownStrategy implements IDropdownStrategy<WlMtsSmsConversionCodes> {
    public getEntities(): Observable<Array<WlMtsSmsConversionCodes>> {
        return of(EnumExtensions.toArray(WlMtsSmsConversionCodes));
    }

    public map(model: WlMtsSmsConversionCodes): DropdownItem<WlMtsSmsConversionCodes> {
        if (isNullOrUndefined(model)) {
            return {
                entity: null,
                title: 'Не выбрано',
                id: ''
            };
        }

        return {
            entity: model,
            title: WlMtsSmsConversionCodesExtensions.format(model),
            id: Number(model).toString()
        };
    }
}
