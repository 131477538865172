import {IDropdownStrategy} from '@koddington/ga-common';
import {ApiStates, ApiStatesExtensions} from 'src/app/services/autogen/ControlPanel';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiStatesDropdownStrategy implements IDropdownStrategy<ApiStates> {
  public getEntities(): Observable<Array<ApiStates>> {
    return of(EnumExtensions.toArray(ApiStates));
  }

  public map(model: ApiStates): DropdownItem<ApiStates> {
    return {
      id: Number(model).toString(),
      title: ApiStatesExtensions.format(model),
      entity: model
    };
  }
}
