import { DropdownItem, IDropdownStrategy } from '@koddington/ga-common';
import { EnumExtensions } from '@koddington/ga-common';
import { GcmResponseStatusExtensions } from '../../../../push-gate/enums/push-mock-gcm-response-enum';
import { Injectable } from '@angular/core';
import { GcmResponseStatus } from '../../../../../services/autogen/PushGate';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GcmResponseStatusDropdownStrategy implements IDropdownStrategy<GcmResponseStatus> {
    public getEntities(): Observable<Array<GcmResponseStatus>> {
        return of(EnumExtensions.toArray(GcmResponseStatus));
    }

    public map(model: GcmResponseStatus): DropdownItem<GcmResponseStatus> {
        return {
            id: Number(model).toString(),
            title: GcmResponseStatusExtensions.toFriendlyString(model),
            entity: model
        };
    }
}
