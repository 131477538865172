import {Routes} from '@angular/router';
import {PushTemplatesListComponent} from './push-templates-list/push-templates-list.component';

export const PushRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: PushTemplatesListComponent
      }
    ]
  }
];
