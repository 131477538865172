import {IDropdownStrategy} from '@koddington/ga-common';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import {
  WlRateRaceExchangeRequestStatus,
  WlRateRaceExchangeRequestStatusExtensions
} from '../../../../../services/autogen/BattlePass';


export class WlRateRaceExchangeRequestStatusDropdownStrategy implements IDropdownStrategy<WlRateRaceExchangeRequestStatus> {

  map(model: WlRateRaceExchangeRequestStatus): DropdownItem<WlRateRaceExchangeRequestStatus> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlRateRaceExchangeRequestStatus>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlRateRaceExchangeRequestStatusExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlRateRaceExchangeRequestStatus>> {
    const types = EnumExtensions.toArray(WlRateRaceExchangeRequestStatus);
    return of(types);
  }
}
