<div class="col-10">
  <h1>Пользователи мок-сервера</h1>
  <div class="col-3">
    <app-users-add (userAdded)="pageFiltered()"></app-users-add>
  </div>
  <hr />
  <div>
    <div *ngIf="loading">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!loading">
      <ga-panel-grid *ngIf="!!searchPanelGrid" [config]="searchPanelGrid"/>
      <button class="btn btn-primary mt-2" (click)="pageFiltered()">Поиск</button>
      <ga-table [data]="tableData" [hideArrows]="true"></ga-table>
      <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
    </div>
  </div>

</div>
