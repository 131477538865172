<div>
  <label class="mt-2">Включение/отключение параметров рассылки (clients_optout)</label>

  <div>
    <div class="row mt-2">
      <div class="col-4">
        Введите Id пользователя
      </div>
      <div class="col-2">
        Параметры
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <app-strict-input-number [control]="model.userId" [placeholder]="'Id пользователя'">
        </app-strict-input-number>
      </div>
      <div class="col-8">
        <div class="row col-6">
          <app-strict-input-number class="col-6" [control]="model.smsRestricted" [placeholder]="'Отключить СМС(0 - нет, 1 - да)'">
          </app-strict-input-number>
          <strict-date-time-picker class="col-6" [placeholder]="'Дата отключения СМС'" [control]="model.smsRestrictedDate"></strict-date-time-picker>
        </div>
        <div class="row col-6">
          <app-strict-input-number class="col-6" [placeholder]="'Отключить Email(0 - нет, 1 - да)'" [control]="model.emailRestricted">
          </app-strict-input-number>
          <strict-date-time-picker class="col-6" [placeholder]="'Дата отключения Email'" [control]="model.emailRestrictedDate"></strict-date-time-picker>
        </div>
      </div>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
  </div>
</div>
