import {IDropdownStrategy} from '@koddington/ga-common';
import {
    WlBattlePassRewardResponseErrorCodes,
    WlBattlePassRewardResponseErrorCodesExtensions
} from '../../../../../enums/wl-battle-pass-reward-response-error-codes';
import { Observable, of } from 'rxjs';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';

export class WlBattlePassRewardProcessingStatesStrategy implements IDropdownStrategy<WlBattlePassRewardResponseErrorCodes> {
    map(model: WlBattlePassRewardResponseErrorCodes): DropdownItem<WlBattlePassRewardResponseErrorCodes> {
        if (isNullOrUndefined(model)) {
            return undefined;
        }
        const item = new DropdownItem<WlBattlePassRewardResponseErrorCodes>();
        item.id = model.toString();
        item.entity = model;
        item.title = WlBattlePassRewardResponseErrorCodesExtensions.toFriendlyString(model);

        return item;
    }

    getEntities(): Observable<Array<WlBattlePassRewardResponseErrorCodes>> {
        const types = EnumExtensions.toArray(WlBattlePassRewardResponseErrorCodes);
        return of(types);
    }
}
