import { BetPeriods, WlSportMatchApiModel } from 'src/app/services/autogen/Events';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class WlBetEventViewModel {
    matchInfo: StrictFormControl<WlSportMatchApiModel> = StrictFormControlBuilder.buildNullObject(WlSportMatchApiModel);
    sportType: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    marketId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    championshipId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    gameId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    rate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    betPeriod: StrictFormControl<BetPeriods> = StrictFormControlBuilder.buildNullNumber<BetPeriods>();
    firstPlayerId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    secondPlayerId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    totalScoreHandicap: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
}
