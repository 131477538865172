import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { RegistrationStateChangeViewModel } from '../../../../models/events/components-models/registration-state-change.view-model';
import { RegistrationStateChangesValidator } from '../../validators/registration-state-changes.validator';
import { RegistrationStateForm } from 'src/app/services/autogen/Events';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';

@Component({
    selector: 'app-add-registration-state-changes',
    templateUrl: './add-registration-state-changes.component.html'
})
export class AddRegistrationStateChangesComponent implements OnInit {
    protected readonly model = new RegistrationStateChangeViewModel();

    constructor(private readonly service: EventsService,
                private readonly validator: RegistrationStateChangesValidator,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
    }

    protected add(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length !== 0) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(RegistrationStateForm, this.model);
        this.service
            .addStateChanges(form)
            .pipe(
                take(1),
                filter(u => !this.messaging.tryShowError(u))
            )
            .subscribe(() => this.messaging.showMessage('Событие добавлено'));
    }
}
