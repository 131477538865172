import { AbstractModelValidator } from '@koddington/ga-common';
import { FavouriteNationalTeamViewModel } from '../../../../../models/events/components-models/FavouriteNationalTeamViewModel';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AddFavouriteNationalTeamValidator extends AbstractModelValidator<FavouriteNationalTeamViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.userId)
            .required()
            .min(1);

        this.ruleForControl(u => u.teamId)
            .required()
            .min(1);

        this.ruleForControl(u => u.changeDate)
            .required();
    }
}
