import { AbstractModelValidator } from '@koddington/ga-common';
import { AddSportMatchViewModel } from '../../../../models/events/components-models/add-sport-match-view-model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AddSportMatchValidator extends AbstractModelValidator<AddSportMatchViewModel> {
    constructor() {
        super();

        this.ruleForControl(u => u.matchId)
            .requiredAndNotZero();

        this.ruleForControl(u => u.sportId)
            .requiredAndNotZero();

        this.ruleForControl(u => u.matchDate)
            .required();

        this.ruleForControl(u => u.category)
            .required();

        this.ruleForControl(u => u.championshipId)
            .required();

        this.ruleForControl(u => u.firstPlayer)
            .required();

        this.ruleForControl(u => u.secondPlayer)
            .required();
    }
}
