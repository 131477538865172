import { Injectable } from "@angular/core";
import { DropdownItem, IDropdownStrategy } from '@koddington/ga-common';
import {EnumExtensions} from '@koddington/ga-common';
import { ApnsResponseStatus, ApnsResponseStatusExtensions } from 'src/app/modules/push-gate/enums/push-mock-apns-response-enum';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
  export class ApnsResponseStatusDropdownStrategy implements IDropdownStrategy<ApnsResponseStatus> {
    public getEntities(): Observable<Array<ApnsResponseStatus>> {
      return of(EnumExtensions.toArray(ApnsResponseStatus));
    }
    public map(model: ApnsResponseStatus): DropdownItem<ApnsResponseStatus> {
      return {
        id: Number(model).toString(),
        title: ApnsResponseStatusExtensions.toFriendlyString(model),
        entity: model
      };
    }
  }
