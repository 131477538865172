import { Component, OnInit } from '@angular/core';
import { UserSearchForm, WlMonetaryClassTypesExtensions, WlStageTypesExtensions, WlUser } from 'src/app/services/autogen/Users';
import { UsersService } from 'src/app/services/autogen/Users';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { formatDateTime, GaPagedResult, GaPagingForm, GaPanelGridConfig, GaSearchManagementStateOptions, GaSearchManagementStateService, GaTableData } from '@koddington/ga-common';
import { UsersListSearchModel } from './models/users-list-search-model';

@UntilDestroy()
@Component({
    selector: 'app-users-list',
    templateUrl: './users-list.component.html',
    providers: [GaSearchManagementStateService]
})
export class UsersListComponent implements OnInit {
    public pagedResult: GaPagedResult<WlUser>;
    public tableData: GaTableData<WlUser>;
    private pagingViewModel = new UsersListSearchModel();
    public searchPanelGrid = new GaPanelGridConfig();

    constructor(private readonly service: UsersService,
                private readonly _searchService: GaSearchManagementStateService<GaPagedResult<WlUser>>) {
    }

    public ngOnInit(): void {
        this.init();
    }

    protected pageChanged(form: GaPagingForm): void {
        this._searchService.nextSearch(form);
    }

    protected pageFiltered(page: GaPagingForm = null): void {
        this._searchService.nextSearch(page, true);
    }

    private init(): void {
        this.searchPanelGrid
            .addNumbers(this.pagingViewModel.id, 'Id пользователя');
        this._searchService.initFromOptions(
            (form) => this.service.list(form as UserSearchForm),
            this.pagingViewModel,
            new GaSearchManagementStateOptions(u => u['tab'] = 'UserAddTab')
        );
        this._searchService.searchState.pipe(untilDestroyed(this)).subscribe(u => {
            this.pagedResult = u;
            this.mapToTable(u.results);
            this._searchService.loadingStatus = false;
        });
    }

    private mapToTable(result: WlUser[]): void {
        this.tableData = new GaTableData<WlUser>()
            .setData(result)
            .addSimpleColumn((elem) => elem.userId, {title: 'Id', widthSize: 110})
            .addSimpleColumn((elem) => elem.name, {title: 'Имя', widthSize: 500})
            .addSimpleColumn((elem) => elem.phone, {title: 'Номер телефона', widthSize: 190})
            .addSimpleColumn((elem) => WlStageTypesExtensions.format(elem.stageType), {title: 'Стадия', widthSize: 100})
            .addSimpleColumn((elem) => WlMonetaryClassTypesExtensions.format(elem.monetaryClassType), {title: 'Монетарный класс', widthSize: 140})
            .addSimpleColumn((elem) => formatDateTime(elem.lastBetDate), {title: 'Дата последней ставки', widthSize: 160})
            .addSimpleColumn((elem) => formatDateTime(elem.segmentDate), {title: 'Дата смены стадии', widthSize: 160});
    }

    get loading(): boolean {
        return this._searchService.loadingStatus;
    }
}
