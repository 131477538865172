import { Dayjs } from 'dayjs';
import { WlBetTypes } from 'src/app/services/autogen/Events';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlBetEventViewModel } from '../../../modules/shared/common/wl-bet-event/models/wl-bet-event-view-model';
import { WlCrmBetPlatforms } from 'src/app/services/autogen/Events';
import { MockAddBetForm } from 'src/app/services/autogen/Events';
import { MockBetEventForm } from 'src/app/services/autogen/Events';

export class AddBetViewModel {
    userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    betDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    betId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    freeBetId?: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    sum: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    betType: StrictFormControl<WlBetTypes> = StrictFormControlBuilder.buildNullNumber<WlBetTypes>();
    platform: StrictFormControl<WlCrmBetPlatforms> = StrictFormControlBuilder.buildNullNumber<WlCrmBetPlatforms>();
    events: StrictFormControl<WlBetEventViewModel[]> = new StrictFormControl<WlBetEventViewModel[]>([]);
    totalRate: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();


    public toForm(): MockAddBetForm {
        const form = new MockAddBetForm();
        form.events = [];
        form.betDate = this.betDate.strictValue;
        form.freeBetId = this.freeBetId.strictValue;
        form.sum = this.sum.strictValue;
        form.userId = this.userId.strictValue;
        form.betType = this.betType.strictValue;
        form.platform = this.platform.strictValue;
        form.betId = this.betId.strictValue;

        this.events.strictValue.forEach(value => {
            const eventBetForm = new MockBetEventForm();
            eventBetForm.eventId = value.matchInfo.strictValue.matchId;
            eventBetForm.marketId = value.marketId.strictValue;
            eventBetForm.sportType = value.sportType.strictValue;
            eventBetForm.championshipId = value.championshipId.strictValue;
            eventBetForm.gameId = value.gameId.strictValue;
            eventBetForm.rate = value.rate.strictValue;
            eventBetForm.betPeriod = value.betPeriod.strictValue;
            eventBetForm.firstTeamId = value.firstPlayerId.strictValue;
            eventBetForm.secondTeamId = value.secondPlayerId.strictValue;
            eventBetForm.totalScoreHandicap = value.totalScoreHandicap.strictValue;

            form.events.push(eventBetForm);
        });

        return form;
    }
}
