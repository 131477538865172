import { Component, OnInit } from '@angular/core';
import { EventsService } from 'src/app/services/autogen/Events';
import { ChannelChangeViewModel } from '../../../../models/events/components-models/channel-change-view-model';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { ChannelChangeValidator } from '../../validators/channel-change-validator';
import { ChannelChangeForm } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-add-sms-operations',
    templateUrl: './add-sms-operations.component.html'
})
export class AddSmsOperationsComponent implements OnInit {

    protected model: ChannelChangeViewModel = new ChannelChangeViewModel();

    constructor(private readonly service: EventsService,
                private readonly validator: ChannelChangeValidator,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
    }

    protected add(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }
        const form = GaStrictModelFactory.fromStrictToModel(ChannelChangeForm, this.model);

        this.service.addChannelChange(form)
            .pipe(
                take(1),
                filter(res => !this.messaging.tryShowError(res))
            )
            .subscribe(() => this.messaging.showMessage('Канал успешно изменён'));
    }
}
