import {Component, OnInit} from '@angular/core';
import {formatDateTime, GaMessagingService, GaPagedResult, GaPagingForm, GaTableData, isNullOrUndefined} from '@koddington/ga-common';
import {SearchNavigationService} from '../../shared/services/search-navigation.service';
import {ActivatedRoute, Params} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {UsersListByIdForm} from '../../../services/autogen/Shared';
import { GaStrictModelFactory } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
    AccrueRewardFormViewModel, AccrueRewardFormViewModelValidator,
    FaceItService,
    MockFaceItReward,
    PartnerRewardsResultCodesExtensions,
    RewardsListFilterForm
} from '../../../services/autogen/FaceIt';
import {FaceItRewardsListFilterViewModel} from '../../../models/face-it/rewards/face-it-rewards-list-filter.view-model';

@UntilDestroy()
@Component({
  selector: 'app-face-it-rewards',
  templateUrl: './face-it-rewards.component.html'
})
export class FaceItRewardsComponent implements OnInit {
  public loading = true;
  public tableData: GaTableData<MockFaceItReward>;
  public pagedResult: GaPagedResult<MockFaceItReward>;

  public pagingViewModel = new FaceItRewardsListFilterViewModel();
  public accrueRewardViewModel = new AccrueRewardFormViewModel();

  private readonly _validator = new AccrueRewardFormViewModelValidator();

  constructor(private readonly _messaging: GaMessagingService,
              private readonly _navigation: SearchNavigationService,
              private readonly _activeRoute: ActivatedRoute,
              private readonly _faceItService: FaceItService) {
  }

  public ngOnInit(): void {
    this.updateCurrentTableState();
  }

  public search(): void {
    this.pagingViewModel.offset.strictValue = 0;

    this.handleFilterChange();
  }

  public create(): void {
      if (this._validator.isNotValid(this.accrueRewardViewModel)) {
          return;
      }

    this.loading = true;

    const model = this.accrueRewardViewModel.toForm();
    this._faceItService.accrueReward(model)
      .pipe(
        take(1),
        filter(res => !this._messaging.tryShowError(res))
      )
      .subscribe(res => {
        this._messaging.showMessage(`Награда ${res.result} выдана.`);
        this.accrueRewardViewModel = new AccrueRewardFormViewModel();
        this.updateCurrentTableState();
      });
  }

  public pageChanged(form: GaPagingForm): void {
    this.pagingViewModel.offset.strictValue = form.offset;
    this.pagingViewModel.count.strictValue = form.count;

    this.handleFilterChange();
  }

  private updateCurrentTableState(): void {
    this._activeRoute.queryParams.pipe(
      map(value => this.initFindViewModel(value)),
      map(() => this.createForm()),
      take(1)
    ).subscribe((value) => this.load(value));
  }

  private initFindViewModel(params: Params) {
    this.pagingViewModel.userId.strictValue = !isNullOrUndefined(params['userId']) ? Number(params['userId']) : null;
    this.pagingViewModel.rewardId.strictValue = !isNullOrUndefined(params['rewardId']) ? Number(params['rewardId']) : null;
    this.pagingViewModel.offset.strictValue = !isNullOrUndefined(params['offset']) ? Number(params['offset']) : 0;
    this.pagingViewModel.count.strictValue = !isNullOrUndefined(params['count']) ? Number(params['count']) : 30;
  }

  private createForm(): RewardsListFilterForm {
    return GaStrictModelFactory.fromStrictToModel(UsersListByIdForm, this.pagingViewModel);
  }

  private load(form: RewardsListFilterForm): void {
    this._faceItService.getRewardsList(form)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.pagedResult = res;
        this.mapTableData(res.results);

        this.loading = false;
      });
  }

  private mapTableData(results: MockFaceItReward[]): void {
    this.tableData = new GaTableData<MockFaceItReward>()
      .addSimpleColumn(u => u.id, { title: 'Id выдачи', widthSize: 100 })
      .addSimpleColumn(u => u.userId, { title: 'Id юзера', widthSize: 100 })
      .addSimpleColumn(u => u.rewardId, { title: 'Id награды', widthSize: 100 })
      .addSimpleColumn(u => u.partnerId, { title: 'Id партнера', widthSize: 100 })
      .addSimpleColumn(u => formatDateTime(u.createdAt), { title: 'Дата запроса на выдачу', widthSize: 160 })
      .addSimpleColumn(u => u.rewardPayload, { title: 'Payload награды', widthSize: 400 })
      .addSimpleColumn(u => u.rewardPayloadTraceId, { title: 'Payload TraceId', widthSize: 400 })
      .addSimpleColumn(u => PartnerRewardsResultCodesExtensions.format(u.retrieveCode), { title: 'Код выдачи', widthSize: 150 })
      .addSimpleColumn(u => formatDateTime(u.retrievedAt), { title: 'Дата выдачи', widthSize: 150 })

      .setData(results);

  }

  private handleFilterChange(): void {
    const params: Params = {
      userId: this.pagingViewModel.userId.hasStrictValue ? String(this.pagingViewModel.userId.strictValue) : null,
      rewardId: this.pagingViewModel.rewardId.hasStrictValue ? String(this.pagingViewModel.rewardId.strictValue) : null,
      offset: this.pagingViewModel.offset.hasStrictValue ? this.pagingViewModel.offset.strictValue : 0,
      count: this.pagingViewModel.count.hasStrictValue ? this.pagingViewModel.count.strictValue : 30
    };

    this._navigation.search(this._activeRoute, params);
  }
}
