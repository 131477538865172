import { WlMockSecurityGroup } from 'src/app/services/autogen/Dictionaries';
import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class UserSegmentationDataViewModel {
    public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public registrationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public securityGroup: StrictFormControl<WlMockSecurityGroup> = StrictFormControlBuilder.buildNullObject(WlMockSecurityGroup);
    public clientName: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public phoneNumber: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public email: StrictFormControl<string> = StrictFormControlBuilder.buildNullString();
    public registrationSource: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public emailRestricted: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public smsRestricted: StrictFormControl<boolean> = new StrictFormControl<boolean>(false);
    public emailRestrictionDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public smsRestrictionDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public operationDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public videoIdentState: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
