import {Component, OnDestroy, OnInit} from '@angular/core';
import { GaMessagingService, StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { UsersService } from 'src/app/services/autogen/Users';
import {filter, first, take} from 'rxjs/operators';
import {ResetResultTypeDropdownStrategy} from '../../../shared/common/enum-dropdown/strategies/reset-result-type-dropdown-strategy';
import { ResetResultType } from 'src/app/services/autogen/Users';
import { ResetUserResultForm, UserStatisticsService } from 'src/app/services/autogen/Users';

@Component({
  selector: 'app-users-reset-result-bet',
  templateUrl: './user-reset-result-bet.component.html',
  styleUrls: ['./user-reset-result-bet.component.css']
})

export class UserResetResultBetComponent implements OnInit, OnDestroy {

  public userIdBet: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public resetResultBetType: StrictFormControl<ResetResultType> = new StrictFormControl<ResetResultType>(0);

  public userIdDeposit: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public resetResultDepositType: StrictFormControl<ResetResultType> = new StrictFormControl<ResetResultType>(0);

  public userIdStat: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  constructor(public readonly strategy: ResetResultTypeDropdownStrategy,
              private readonly messaging: GaMessagingService,
              private readonly service: UsersService,
              private readonly statisticsService: UserStatisticsService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {

  }

  get getNameResetResultBet(): string {
    return this.strategy.map(this.resetResultBetType.strictValue).title;
  }

  get getNameResetResultDeposit(): string {
    return this.strategy.map(this.resetResultDepositType.strictValue).title;
  }

  public getUserIdBetHasValue(): boolean {
    return !(this.userIdBet.hasStrictValue && this.resetResultBetType.hasStrictValue);
  }

  public getUserIdDepositHasValue(): boolean {
    return !(this.userIdDeposit.hasStrictValue && this.resetResultDepositType.hasStrictValue);
  }
  public getUserIdStatsHasValue(): boolean {
    return !(this.userIdStat.hasStrictValue);
  }

  public resetBet(): void {
    const form = new ResetUserResultForm();
    form.userId = this.userIdBet.strictValue;
    form.type = this.resetResultBetType.strictValue;

    this.statisticsService.resetBetsResults(form)
      .pipe(
        take(1),
        filter(err => !this.messaging.tryShowError(err)))
        .subscribe(() => this.messaging.showMessage(`История расчёта ставок ${this.getNameResetResultBet} сброшена`)
      );
  }

  public resetDeposit(): void {
    const form = new ResetUserResultForm();
    form.userId = this.userIdDeposit.strictValue;
    form.type = this.resetResultDepositType.strictValue;

    this.statisticsService.resetFinancialOperations(form)
      .pipe(
        take(1),
        filter(err => !this.messaging.tryShowError(err)))
      .subscribe(() => this.messaging.showMessage(`История депозитов ${this.getNameResetResultDeposit} сброшена`)
      );
  }
  public dropUserBetStat(): void {
    this.statisticsService.deleteUserBetsStats(this.userIdStat.strictValue)
      .pipe(
        first(),
        filter(err => !this.messaging.tryShowError(err)))
      .subscribe(() => this.messaging.showMessage(`История ставок пользователя ${this.userIdStat.strictValue} сброшена`)
      );
  }
}
