<div>
    <h3>Создание кастомного турнира</h3>
    <div class="mt-2 row">
        <app-strict-input-number class="col-1"
                                 title="Id турнира"
                                 [control]="viewModel.tournamentId">
        </app-strict-input-number>
        <app-strict-input-text class="col-2"
                               title="Наименование турнира"
                               [control]="viewModel.name">
        </app-strict-input-text>
        <app-strict-dropdown class="col-1"
                             [options]="{placeholder: 'Выберите тип', title: 'Тип турнира'}"
                             [control]="viewModel.tournamentType"
                             [strategy]="tournamentTypesStrategy"
        ></app-strict-dropdown>
        <app-strict-input-number class="col-1"
                                 title="Призовой фонд"
                                 [control]="viewModel.prizeFund">
        </app-strict-input-number>
        <app-strict-dropdown class="col-1"
                             [options]="{placeholder: 'Выберите тип', title: 'Тип призового фонда'}"
                             [control]="viewModel.prizeFundType"
                             [strategy]="prizeFundTypesStrategy"
        ></app-strict-dropdown>
        <app-strict-input-number class="col-1"
                                 title="Число призёров"
                                 [control]="viewModel.winnersCount">
        </app-strict-input-number>
        <app-strict-input-number class="col-1"
                                 title="Число участников"
                                 [control]="viewModel.usersCount">
        </app-strict-input-number>
        <app-strict-input-boolean class="col-1"
                                  title="Запущен"
                                  [control]="viewModel.isActive">
        </app-strict-input-boolean>
    </div>
    <div class="mt-2 row">
        <strict-date-time-picker class="col-2"
                                 title="Дата регистрации"
                                 [control]="viewModel.registrationDate">
        </strict-date-time-picker>
        <strict-date-time-picker class="col-2"
                                 title="Дата начала"
                                 [control]="viewModel.startDate">
        </strict-date-time-picker>
        <strict-date-time-picker class="col-2"
                                 title="Дата завершения"
                                 [control]="viewModel.endDate">
        </strict-date-time-picker>
    </div>
    <button class="btn btn-primary mt-2" (click)="add()">Сохранить</button>
</div>
