import { Component, OnInit } from '@angular/core';
import { BattlePassService, ChangeRewardDictionaryFormViewModel, ChangeRewardDictionaryFormViewModelValidator } from 'src/app/services/autogen/BattlePass';
import { GaMessagingService, GaPagedResult, GaPagingForm, GaStrictModelFactory } from '@koddington/ga-common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WlBattlePassRewardListModel } from 'src/app/services/autogen/BattlePass';
import { ChangeRewardDictionaryForm } from 'src/app/services/autogen/BattlePass';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector: 'app-battle-pass-reward-dictionary-list',
    templateUrl: './battle-pass-reward-dictionary-list.component.html'
})
export class BattlePassRewardDictionaryListComponent implements OnInit {

    public result: GaPagedResult<WlBattlePassRewardListModel>;
    public viewModel = new ChangeRewardDictionaryFormViewModel();
    private readonly _validator = new ChangeRewardDictionaryFormViewModelValidator();

    constructor(private readonly _battlePassService: BattlePassService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
        this.load();
    }

    public pageChanged(form: GaPagingForm): void {
        this.load(form.offset);
    }

    public change(rewardId: number): void {
        const reward = this.result.results
                           .filter((value) => value.id === rewardId)[0];

        this.viewModel = new ChangeRewardDictionaryFormViewModel();
        GaStrictModelFactory.fromModelToStrict(this.viewModel, reward, null, null, 'resultChange', 'errorText');
    }

    public send(): void {
        if (this._validator.isNotValid(this.viewModel)) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(ChangeRewardDictionaryForm, this.viewModel);

        this._battlePassService.changeReward(form)
            .pipe(
                take(1),
                filter(u => !this._messaging.tryShowError(u))
            )
            .subscribe(() => this._messaging.showMessage('Изменение отправленно'));
    }

    private load(offset: number = 0): void {
        const paging = new GaPagingForm();
        paging.offset = offset;

        this._battlePassService.listRewards(paging)
            .pipe(untilDestroyed(this))
            .subscribe(res => this.result = res);
    }
}
