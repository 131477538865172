import { Component, OnInit } from '@angular/core';
import { WlCustomTournamentTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-custom-tournament-types-strategy';
import { AddOrUpdateCustomTournamentFormViewModel, AddOrUpdateCustomTournamentFormViewModelValidator, CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import {
    AddOrUpdateCustomTournamentForm
} from '../../../../services/autogen/CustomTournaments';
import { WlTournamentPrizeFundTypesStrategy } from '../../../shared/common/enum-dropdown/strategies/wl-tournament-prize-fund-types-strategy';

@Component({
    selector: 'app-custom-tournaments-item-crud',
    templateUrl: './custom-tournaments-item-crud.component.html'
})
export class CustomTournamentsItemCrudComponent implements OnInit {
    public readonly viewModel = new AddOrUpdateCustomTournamentFormViewModel();
    private readonly _validator = new AddOrUpdateCustomTournamentFormViewModelValidator();

    constructor(public readonly tournamentTypesStrategy: WlCustomTournamentTypesStrategy,
                public readonly prizeFundTypesStrategy: WlTournamentPrizeFundTypesStrategy,
                private readonly _service: CustomTournamentService,
                private readonly _messaging: GaMessagingService) {
    }

    public ngOnInit(): void {
    }

    public add(): void {
        if (this._validator.isNotValid(this.viewModel)) {
            return;
        }
        const form = GaStrictModelFactory.fromStrictToModelSimple(AddOrUpdateCustomTournamentForm, this.viewModel);
        this._service
            .addOrUpdateTournament(form)
            .pipe(
                take(1),
                filter(u => !this._messaging.tryShowError(u))
            )
            .subscribe(() => this._messaging.showMessage('Турнир успешно добавлен'));
    }
}
