import {IDropdownStrategy} from '@koddington/ga-common';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { Observable, of } from 'rxjs';
import {EnumExtensions, isNullOrUndefined} from '@koddington/ga-common';
import { Injectable } from '@angular/core';
import { WlStageTypes, WlStageTypesExtensions } from 'src/app/services/autogen/Users';

@Injectable({
  providedIn: 'root'
})
export class StageTypesDropdownStrategy implements IDropdownStrategy<WlStageTypes> {

  map(model: WlStageTypes): DropdownItem<WlStageTypes> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlStageTypes>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlStageTypesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlStageTypes>> {
    const types = EnumExtensions.toArray(WlStageTypes);
    return of(types);
  }
}
