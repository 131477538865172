<h1>Прогресс пользователя по игровым заданиям</h1>

<div class="row">
  <app-strict-input-number class="col-6" [control]="model.userId"
                           [placeholder]="'Id пользователя'"></app-strict-input-number>
  <span>
    <button class="btn btn-primary" (click)="search()">Поиск</button>
  </span>
</div>

<div class="row mb-3">
  <div class="col-1">
    Id пользователя
  </div>
  <div class="col-1">
    Id подписки
  </div>
  <div class="col-1">
    Задание
  </div>
  <div class="col-1">
    Текущий прогресс
  </div>
  <div class="col-1">
    Целевой прогресс
  </div>
  <div class="col-1">
    Дата события
  </div>
</div>
<div *ngFor="let result of results;let i = index">
  <div class="row">
    <div class="col-1">
      {{result.userId}}
    </div>
    <div class="col-1">
      {{result.subscriptionId}}
    </div>
    <div class="col-1">
      {{result.questId}}
    </div>
    <div class="col-1">
      {{result.currentProgress}}
    </div>
    <div class="col-1">
      {{result.targetProgress}}
    </div>
    <div class="col-1">
      {{result.eventDate | dateTime}}
    </div>
  </div>
</div>

<div *ngIf="pagedResult">
  <ga-paging [pagedResult]="pagedResult" (pageChangedEvent)="pageChanged($event)"></ga-paging>
</div>

