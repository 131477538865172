import {IDropdownStrategy} from '@koddington/ga-common';
import {WlBetTypes, WlBetTypesExtensions} from 'src/app/services/autogen/Shared';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class BetTypesStrategy implements IDropdownStrategy<WlBetTypes> {

  map(model: WlBetTypes): DropdownItem<WlBetTypes> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<WlBetTypes>();
    item.id = model.toString();
    item.entity = model;
    item.title = WlBetTypesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<WlBetTypes>> {
    const types = EnumExtensions.toArray(WlBetTypes).filter(value => value !== 0);
    return of(types);
  }
}
