import {IDropdownStrategy} from '@koddington/ga-common';
import {
  WinlineMechanicEnablingStatus,
  WinlineMechanicEnablingStatusExtensions
} from 'src/app/services/autogen/UserMechanics';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {EnumExtensions} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class MechanicEnablingRequestStatusDropdownStrategy implements IDropdownStrategy<WinlineMechanicEnablingStatus> {
  public getEntities(): Observable<Array<WinlineMechanicEnablingStatus>> {
    return of(EnumExtensions.toArray(WinlineMechanicEnablingStatus));
  }

  public map(model: WinlineMechanicEnablingStatus): DropdownItem<WinlineMechanicEnablingStatus> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: WinlineMechanicEnablingStatusExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
