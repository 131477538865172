import { Component, OnInit } from '@angular/core';
import { XFiveResultViewModel } from '../../../../models/events/components-models/x-five-result-view-model';
import { EventsService } from 'src/app/services/autogen/Events';
import { XFiveResultValidator } from '../../validators/x-five-result-validator';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { XfiveResultForm } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';

@Component({
    selector: 'app-x-five-result',
    templateUrl: './x-five-result.component.html'
})
export class XFiveResultComponent implements OnInit {

    protected readonly model: XFiveResultViewModel = new XFiveResultViewModel();

    constructor(private readonly service: EventsService,
                private readonly validator: XFiveResultValidator,
                private readonly messaging: GaMessagingService) {
    }

    ngOnInit(): void {
    }

    protected add(): void {
        const errors = this.validator.validate(this.model);
        if (errors.length > 0) {
            return;
        }
        const form = GaStrictModelFactory.fromStrictToModel(XfiveResultForm, this.model);
        this.service.xfiveResultAdd(form)
            .pipe(
                take(1),
                filter(res => !this.messaging.tryShowError(res)))
            .subscribe(() => this.messaging.showMessage('Расчёта купона X5 успешно выполнен'));
    }
}
