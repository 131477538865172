<div class="container-fluid">
  <div class="row mt-4">
    <div class="col-6">
      <h3>Изменение группы службы безопасности</h3>
      <div class="form-group">
        <app-strict-input-number [control]="changeGroupModel.userId" placeholder="Id">
        </app-strict-input-number>
      </div>
      <div class="form-group">
        <app-strict-autocomplete placeholder="Группы безопасности"
                                 [strategy]="securityGroupStrategy"
                                 [control]="changeGroupModel.securityGroup">
        </app-strict-autocomplete>
      </div>
      <button class="btn btn-primary mt-2" (click)="changeSecurityGroup()">Сохранить</button>
    </div>
    <div class="col-6">
      <app-last-segmentation-state></app-last-segmentation-state>
    </div>
  </div>
  <hr>

  <h1>Профили для сегментации пользователей</h1>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <table class="table mt-3">
    <thead>
    <tr>
      <th scope="col">Id</th>
      <th scope="col">Группа службы безопасности</th>
      <th scope="col">Монетарный класс</th>
      <th scope="col">Id привязанного матча топ-1</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of userProfiles">
      <th>{{user.userId}}</th>
      <td>{{user.securityGroupName}}</td>
      <td>{{user.monetaryClassName}}</td>
      <td>{{user.nextTopMatchId}}</td>
    </tr>
    </tbody>
  </table>
  <div class="row">
    <span>
      <a (click)="runSegmentation()" class="btn btn-primary mt-2">Запустить сегментацию</a>
      <a [routerLink]="['/segments/userProfiles']" class="btn btn-primary mx-2 mt-2">Список синхронизированных профилей</a>
    </span>
  </div>
</div>

<div>
<app-segmentation-layout></app-segmentation-layout>
</div>
