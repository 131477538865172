import {Injectable} from '@angular/core';
import {IDropdownStrategy} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {CallResultsTypes, CallResultsTypesExtensions} from 'src/app/services/autogen/Events';


@Injectable({
  providedIn: 'root'
})

export class CallResultsTypesStrategy implements IDropdownStrategy<CallResultsTypes> {

  map(model: CallResultsTypes): DropdownItem<CallResultsTypes> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<CallResultsTypes>();
    item.id = model.toString();
    item.entity = model;
    item.title = CallResultsTypesExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<CallResultsTypes>> {
    const types = EnumExtensions.toArray(CallResultsTypes);
    return of(types);
  }

}
