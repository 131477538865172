import {IDropdownStrategy} from '@koddington/ga-common';
import {ResetResultType, ResetResultTypeExtensions} from 'src/app/services/autogen/Users';
import {EnumExtensions} from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ResetResultTypeDropdownStrategy implements IDropdownStrategy<ResetResultType> {
  public getEntities(): Observable<Array<ResetResultType>> {
    return of(EnumExtensions.toArray(ResetResultType));
  }

  public map(model: ResetResultType): DropdownItem<ResetResultType> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: ResetResultTypeExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
