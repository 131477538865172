import {IDropdownStrategy} from '@koddington/ga-common';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import { Observable, of } from 'rxjs';
import {
    WlBattlePassFreeBetRequestStatus,
    WlBattlePassFreeBetRequestStatusExtensions
} from 'src/app/services/autogen/BattlePass';


export class WlBattlePassFreeBetRequestStatusDropdownStrategy implements IDropdownStrategy<WlBattlePassFreeBetRequestStatus> {

    map(model: WlBattlePassFreeBetRequestStatus): DropdownItem<WlBattlePassFreeBetRequestStatus> {
        if (isNullOrUndefined(model)) {
            return undefined;
        }
        const item = new DropdownItem<WlBattlePassFreeBetRequestStatus>();
        item.id = model.toString();
        item.entity = model;
        item.title = WlBattlePassFreeBetRequestStatusExtensions.format(model);

        return item;
    }

    getEntities(): Observable<Array<WlBattlePassFreeBetRequestStatus>> {
        const types = EnumExtensions.toArray(WlBattlePassFreeBetRequestStatus);
        return of(types);
    }
}
