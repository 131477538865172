import { AbstractModelValidator } from '@koddington/ga-common';
import { XFiveResultViewModel } from '../../../models/events/components-models/x-five-result-view-model';
import { Injectable } from '@angular/core';

@Injectable()
export class XFiveResultValidator extends AbstractModelValidator<XFiveResultViewModel> {
    constructor() {
        super();
        this.ruleForControl(m => m.userId)
            .required()
            .min(1);

        this.ruleForControl(m => m.loyaltyLevel)
            .required()
            .min(1).max(3);

        this.ruleForControl(m => m.userDate)
            .required();

        this.ruleForControl(m => m.winsCount)
            .required();
    }
}
