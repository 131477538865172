import {IDropdownStrategy} from '@koddington/ga-common';
import { isNullOrUndefined } from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {DateOrder, DateOrderExtensions} from 'src/app/services/autogen/Shared';

@Injectable({
  providedIn: 'root'
})

export class DateOrderDropdownStrategy implements IDropdownStrategy<DateOrder> {

  map(model: DateOrder): DropdownItem<DateOrder> {
    if (isNullOrUndefined(model)) {
      return undefined;
    }
    const item = new DropdownItem<DateOrder>();
    item.id = model.toString();
    item.entity = model;
    item.title = DateOrderExtensions.format(model);

    return item;
  }

  getEntities(): Observable<Array<DateOrder>> {
    const types = EnumExtensions.toArray(DateOrder);
    return of(types);
  }

}

