import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';

export class AddFreeBetViewModel {
  public userId = StrictFormControlBuilder.buildNullNumber();
  public freeBetId = StrictFormControlBuilder.buildNullNumber();
  public issuedFreebetId = StrictFormControlBuilder.buildNullNumber();
  public summ = new StrictFormControl<number>(0);
  public freeBetType = StrictFormControlBuilder.buildNullNumber();
  public beginDate = StrictFormControlBuilder.buildNullDayjs();
  public endDate = StrictFormControlBuilder.buildNullDayjs();
  public typeForBonusClub = StrictFormControlBuilder.buildNullNumber();
}
