import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { WlMatchTeam } from 'src/app/services/autogen/Dictionaries';
import { Dayjs } from 'dayjs';

export class AddSportMatchViewModel {
    public matchId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public championshipId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public category: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
    public matchDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
    public firstPlayer: StrictFormControl<WlMatchTeam> = StrictFormControlBuilder.buildNullObject(WlMatchTeam);
    public secondPlayer: StrictFormControl<WlMatchTeam> = StrictFormControlBuilder.buildNullObject(WlMatchTeam);
    public sportId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
}
