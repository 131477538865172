import { Component, OnInit } from '@angular/core';
import { FavouriteNationalTeamViewModel } from '../../../../models/events/components-models/FavouriteNationalTeamViewModel';
import { EventsService } from 'src/app/services/autogen/Events';
import { filter, take } from 'rxjs/operators';
import { FavouriteNationalTeamChangeForm } from 'src/app/services/autogen/Events';
import { GaMessagingService, GaPanelGridConfig, GaStrictModelFactory, GaTitlePositions } from '@koddington/ga-common';
import { AddFavouriteNationalTeamValidator } from './validators/add-favourite-national-team.validator';

@Component({
    selector: 'app-add-favourite-national-team-changes',
    templateUrl: './add-favourite-national-team-changes.component.html'
})
export class AddFavouriteNationalTeamChangesComponent implements OnInit {

    protected viewModel = new FavouriteNationalTeamViewModel();
    protected config = new GaPanelGridConfig();

    constructor(private readonly _service: EventsService,
                private readonly _messaging: GaMessagingService,
                private readonly _validator: AddFavouriteNationalTeamValidator) {
    }

    ngOnInit(): void {
        this.buildGrid();
    }

    public changeTeam(): void {
        if (this._validator.isNotValid(this.viewModel)) {
            return;
        }

        const form = GaStrictModelFactory.fromStrictToModel(FavouriteNationalTeamChangeForm, this.viewModel);

        this._service.favoriteNationalTeamChange(form)
            .pipe(
                take(1),
                filter(value => !this._messaging.tryShowError(value)),
            ).subscribe(() => this._messaging.showMessage('Любимая сборная успешна изменена'));
    }

    private buildGrid() {
        this.config.columnsCount = 5;
        this.config.titlePositions = GaTitlePositions.Top;
        this.config.addNumbers(this.viewModel.userId, 'Id пользователя')
            .addNumbers(this.viewModel.teamId, 'Id команды')
            .addDateTime(this.viewModel.changeDate, 'Дата смены');
    }
}
