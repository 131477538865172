import {IDropdownStrategy} from '@koddington/ga-common';
import {
  WinlineMechanicTriggeringStatus,
  WinlineMechanicTriggeringStatusExtensions
} from 'src/app/services/autogen/UserMechanics';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import { isNullOrUndefined } from '@koddington/ga-common';

@Injectable({
  providedIn: 'root'
})
export class MechanicTriggeringRequestStatusDropdownStrategy implements IDropdownStrategy<WinlineMechanicTriggeringStatus> {
  public getEntities(): Observable<Array<WinlineMechanicTriggeringStatus>> {
    return of(EnumExtensions.toArray(WinlineMechanicTriggeringStatus));
  }

  public map(model: WinlineMechanicTriggeringStatus): DropdownItem<WinlineMechanicTriggeringStatus> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: WinlineMechanicTriggeringStatusExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
