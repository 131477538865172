import { StrictFormControl, StrictFormControlBuilder } from '@koddington/ga-common';
import { Dayjs } from 'dayjs';

export class ChannelChangeViewModel {
  public userId: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public emailRestricted: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public smsRestricted: StrictFormControl<number> = StrictFormControlBuilder.buildNullNumber();
  public emailRestrictedDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
  public smsRestrictedDate: StrictFormControl<Dayjs> = StrictFormControlBuilder.buildNullDayjs();
}

