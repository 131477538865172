import { WlBattlePassQuestSubscriptionStates, WlBattlePassQuestSubscriptionStatesExtensions } from '../../../../../enums/wl-battle-pass-quest-subscription-states';
import { EnumExtensions, isNullOrUndefined } from '@koddington/ga-common';
import {IDropdownStrategy} from '@koddington/ga-common';
import { DropdownItem } from '../../../../../models/common/enum-dropdown/dropdown-item';
import { Observable, of } from 'rxjs';

export class WlBattlePassQuestSubscriptionStatesStrategy implements IDropdownStrategy<WlBattlePassQuestSubscriptionStates> {
    map(model: WlBattlePassQuestSubscriptionStates): DropdownItem<WlBattlePassQuestSubscriptionStates> {
        if (isNullOrUndefined(model)) {
            return undefined;
        }
        const item = new DropdownItem<WlBattlePassQuestSubscriptionStates>();
        item.id = model.toString();
        item.entity = model;
        item.title = WlBattlePassQuestSubscriptionStatesExtensions.toFriendlyString(model);

        return item;
    }

    getEntities(): Observable<Array<WlBattlePassQuestSubscriptionStates>> {
        const types = EnumExtensions.toArray(WlBattlePassQuestSubscriptionStates);
        return of(types);
    }
}
