import {IDropdownStrategy} from '@koddington/ga-common';
import {Observable, of} from 'rxjs';
import {EnumExtensions} from '@koddington/ga-common';
import {DropdownItem} from '../../../../../models/common/enum-dropdown/dropdown-item';
import { isNullOrUndefined } from '@koddington/ga-common';
import {Injectable} from '@angular/core';
import {WlSmsChannels, WlSmsChannelsExtensions } from '../../../../../services/autogen/Shared';

@Injectable({
  providedIn: 'root'
})
export class SmsChannelsTypesDropdownStrategy implements IDropdownStrategy<WlSmsChannels> {
  public getEntities(): Observable<Array<WlSmsChannels>> {
    return of(EnumExtensions.toArray(WlSmsChannels));
  }

  public map(model: WlSmsChannels): DropdownItem<WlSmsChannels> {
    if (isNullOrUndefined(model)) {
      return {
        entity: null,
        title: 'Не выбрано',
        id: ''
      };
    }

    return {
      entity: model,
      title: WlSmsChannelsExtensions.format(model),
      id: Number(model).toString()
    };
  }
}
