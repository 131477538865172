import { Component, OnInit } from '@angular/core';
import { AddUserPlaceFormViewModel, AddUserPlaceFormViewModelValidator, CustomTournamentService } from 'src/app/services/autogen/CustomTournaments';
import { filter, take } from 'rxjs/operators';
import { GaMessagingService, GaStrictModelFactory } from '@koddington/ga-common';
import { AddUserPlaceForm } from 'src/app/services/autogen/CustomTournaments';

@Component({
    selector: 'app-custom-tournaments-table-item-crud',
    templateUrl: './custom-tournaments-table-item-crud.component.html'
})
export class CustomTournamentsTableItemCrudComponent implements OnInit {
    public readonly viewModel: AddUserPlaceFormViewModel = new AddUserPlaceFormViewModel();
    private readonly _validator = new AddUserPlaceFormViewModelValidator();

    constructor(private readonly _service: CustomTournamentService,
                private readonly _messaging: GaMessagingService,
    ) {
    }

    public ngOnInit(): void {
    }

    public add(): void {
        if (this._validator.isNotValid(this.viewModel)) {
            return;
        }
        const form = GaStrictModelFactory.fromStrictToModel(AddUserPlaceForm, this.viewModel);

        this._service
            .addUserPlace(form)
            .pipe(
                take(1),
                filter(u => !this._messaging.tryShowError(u))
            )
            .subscribe(() => this._messaging.showMessage('Место в турнире успешно добавлено'));
    }
}
